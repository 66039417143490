@import ".scss/variables/colors";

@mixin button($base-color) {
    $darker: mix(black, $base-color, 16%);
    $lighter: mix($white-color, $base-color, 16%);
    $disabled: mix($white-color, $base-color, 50%);

    background-color: $base-color;
    color: $white-color;
    box-shadow: inset 0 -2px 0 $darker;

    &:hover,
    &:active,
    &:focus {
        box-shadow: none;
        outline: none;
    }

    &:hover {
        background-color: $darker;
    }

    &:active,
    &:focus {
        background-color: $lighter;
    }

    &:disabled {
        background-color: $disabled;
        box-shadow: none;
    }
}

@mixin button-outline($base-color) {
    $disabled: mix($white-color, $base-color, 50%);

    background: $white-color;
    color: $base-color;
    box-shadow: inset 0 0 0 1px $button-base-outline;

    &:not(:disabled) {
        &:hover,
        &:focus {
            box-shadow: inset 0 0 0 1px $base-color;
        }

        &:active {
            background: $base-color;
            color: $white-color;
        }
    }

    &:disabled {
        box-shadow: inset 0 0 0 1px $button-base-outline;
        color: $disabled;
    }
}
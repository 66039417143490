.legendLabel {
    padding: 0 10px 0 2px;
    font-size: 13px;
    line-height: 15px;
}

.legendColorBox > div {
    border: none !important;
    padding: 0 3px !important;
}

.flotTip {
    padding: 3px 5px;
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid #808697;
    border-radius: 3px;
}

.flot-custom-legend-graph {
    float: left;
}

.flot-custom-legend-legend .legendLabel {
    padding: 4px;
}

.axisLabels.xaxisLabel {
    font-size: 12px;
    color: #545454;
}
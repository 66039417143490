// THEME COLORS
// ------------
$white-color: #FFF; // White Color (Background for form elements like buttons and panels)
$black-color: #000;

// PRIMARY
$theme-primary-color: #0077C8; // Primary Theme Color (deprecated; use $brand2-main-color)
$theme-primary-dark-color: #016AAF; // Primary Dark Theme Color ()

// SECONDARY
$theme-secondary-color: #FA6105; // Secondary Theme Color (deprecated; use $brand1-main-color)

// TERTIARY
$theme-tertiary-color: #B41445; // Tertiary Theme Color ()

// TEXT COLORS
// -----------
$text-color: #1D2940; // Text Color (deprecated; use $font-color)
$text-placeholder-color: #A4AFB7; // Placeholder Text Color ()

// SYSTEM COLORS
// -------------
$system-line-color: #EBEBF2; // System Line Color ()
$system-background-color: #ECEDF0; // System Background Color ()
$system-selected-color: #EBF8FF; // System Selected Color (deprecated; use $secondary-5-color)

$grey-secondary-4: #8DA4AC; // Grey Secondary 4 (deprecated; use $secondary-4-color)

// Brand 1
$brand1-main-color: #FA6105; // Main Brand1 Color (Accented CTA)
$brand1-shadow-color: #FA6105; // Shadow Brand1 Color (Accented CTA)
$brand1-outline-color: #FA6105; // Outline Border Brand1 Color (Accented CTA)
$brand1-hover-color: #D15104; // Hover Brand1 Color (Accented CTA)
$brand1-pressed-color: #FA7A2D; // Pressed Brand1 Color (Accented CTA)
$brand1-disabled-color: #FCB082; // Disabled Brand1 Color (Accented CTA)

// Brand 2
$brand2-main-color: #0077C8; // Main Brand2 Color (Primary CTA, radiobuttons selected state)
$brand2-shadow-color: #0077C8; // Shadow Brand2 Color (Primary CTA, radiobuttons selected state)
$brand2-outline-color: #0077C8; // Outline Border Brand2 Color (Primary CTA, radiobuttons selected state)
$brand2-hover-color: #0063A7; // Hover Brand2 Color (Primary CTA, radiobuttons selected state)
$brand2-pressed-color: #298CD0; // Pressed Brand2 Color (Primary CTA, radiobuttons selected state)
$brand2-disabled-color: #80BBE3; // Disabled Brand2 Color (Primary CTA, radiobuttons selected state)

// Success
$success-main-color: #00994B; // Main Success Color (Success buttons)
$success-shadow-color: #00994B; // Shadow Success Color (Success buttons)
$success-outline-color: #00994B; // Outline Border Success Color (Success buttons)
$success-hover-color: #00813F; // Hover Success Color (Success buttons)
$success-pressed-color: #28A967; // Pressed Success Color (Success buttons)
$success-disabled-color: #7FCBA4; // Disabled Success Color (Success buttons)

// Warning
$warning-main-color: #FFD548; // Main Warning Color (Warning buttons and notifications)
$warning-shadow-color: #FFD548; // Shadow Warning Color (Warning buttons and notifications)
$warning-outline-color: #FFD548; // Outline Border Warning Color (Warning buttons and notifications)
$warning-hover-color: #D7B33C; // Hover Warning Color (Warning buttons and notifications)
$warning-pressed-color: #FFDB64; // Pressed Warning Color (Warning buttons and notifications)
$warning-disabled-color: #FFE9A3; // Disabled Warning Color (Warning buttons and notifications)
$warning-background-color: #F5CC45; // Backgroud Warning Color (Warning buttons and notifications)

// Danger
$danger-main-color: #F00427; // Main Danger Color (Errors, remove buttons)
$danger-shadow-color: #A00322; // Shadow Danger Color (Errors, remove buttons)
$danger-outline-color: #F00322; // Outline Border Danger Color (Errors, remove buttons)
$danger-hover-color: #CA024F; // Hover Danger Color (Errors, remove buttons)
$danger-pressed-color: #F22A44; // Pressed Danger Color (Errors, remove buttons)
$danger-disabled-color: #F78090; // Disabled Danger Color (Errors, remove buttons)

// Toasts
$toast-success: #6EC881;
$toast-info: #44A8E2;
$toast-warning: #F4B400;
$toast-error: #D73D32;

// Modals
$modal-success: #53A93F;
$modal-info: #57B5E3;
$modal-warning: #F4B400;
$modal-danger: #D73D32;
$modal-success-border: #A0D468;
$modal-info-border: #57B5E3;
$modal-warning-border: #FFCE55;
$modal-danger-border: #E46F61;

$font-color: #1D2940; // Font Color (Font)
$font-color-lighter: #646C71; // o.a color subtext

$secondary-1-color: #86BBD8; // Secondary 1 Color (Not used)
$secondary-2-color: #2F4858; // Secondary 2 Color (Menu background)
$secondary-3-color: #B21545; // Secondary 3 Color (Not used)
$secondary-4-color: #8DA4AC; // Secondary 4 Color (Table header)
$secondary-5-color: #EBF8FF; // Secondary 5 Color (Background for selected states of form elements like tabs and chips)
$background-color: #F9F9F9; // Background Color (Background)
$opacity-color: #000; // Opacity Color (Opacity layer behind dialogs, opacity 0.3)
$grey-1-color: #68758D; // Grey 1 Border Color (Form element unselected state)
$grey-2-color: #A2A2A2; // Grey 2 Color (Disabled states)
$grey-3-color: #DADEE0; // Grey 3 Border Color (Outline and icon fill (X to close))
$grey-4-color: #E8ECEE; // Grey 4 Color (Lines for styling and search box, hover for context menu)

$bg-color-navigation: #1D2940;

/* Base colors SnelStart buttons */
$button-base-primary: #FA6105;
$button-base-secondary: #0077C8;
$button-base-success: #048852;
$button-base-danger: #E83232;
$button-base-warning: #FAD044;

$button-base-outline: #DADEE0;
$button-text-disabled: #A2A2A2;

/* Base colors Gridstatus labels */
$grid-label-success: #00994B;
$grid-label-warning: #FFD548;
$grid-label-danger: #E83232;
$grid-label-info: #0077C8;
$grid-label-warning-text: #535966;
@import "mixins";
@import ".scss/variables/colors";

[hidden],
template {
    display: none !important;
}

.popover { z-index: 898; }

.lead {
    font-size: 14px;
    line-height: 24px;
    font-weight: normal;
}

.center-block {
    display: table;
    margin-left: auto;
    margin-right: auto;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
 
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
 
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
    position: inherit !important;
}

/* Well */
.well {
    background-color: #F5F5F5;
    border: 1px solid #CCC;
    border-radius: 4px;
    padding: 20px;
}

.desktop-webview {
    .well {
        border-radius: 0;
    }
}

/* Panel */
.panel {
    border-radius: 0;
    border: none;
    background: none;
    box-shadow: none;
}

.panel-heading {
    background: #FDFDFD;
    border-radius: 0;
    border-bottom: 0 solid #DADADA;
    padding: 10px;
}

.panel-title {
    color: #33353F;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    padding: 0;
    text-transform: none;
}

.panel-footer {
    border-radius: 0;
    margin-top: -5px;
}

.panel-body {
    @include box-shadow(0, 1px, 1px, rgba(0,0,0,0.05), false);
    padding: 10px;
    background-color: $white-color;
}

/* Text color */
.text-primary { color: #0176C3 !important; }
.text-warning { color: #ED9C28 !important; }
.text-success { color: #47A447 !important; }
.text-danger { color: #D2322D !important; }
.text-muted { color: #999 !important; }
.text-muted-extra { color: #B3B3B3 !important; }

/* Form Elements */
input { outline: none; }
label { font-weight: normal; }
textarea { resize: vertical; }

select.input-sm,
select.input-lg {
    line-height: 1;
}

.checkbox.disabled label,
.radio.disabled label {
    opacity: 0.8;
    text-decoration: line-through;
    cursor: not-allowed;
}

.form-control:focus {
    border-color: #0099E6;
    outline: 0;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 136, 204, 0.3);
}

.desktop-webview {
    .form-control,
    .form-control:focus {
        height: auto;
        padding: 4px;
        border-radius: 0;
        box-shadow: none;
    }
}

.input-group .form-control { 
    z-index: auto;

    &:focus {
        z-index: auto;
    }
}

.input-group-addon {
    color: #848484;
    background-color: #F5F5F5;
    padding: 6px 7px;
    cursor: text;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.input-lg {
    border-radius: 4px;
}

/* Backgrounds */
.bg-primary,
 
.bg-secondary,
.bg-success,
.bg-warning {
    color: white;
}

.bg-primary { background: #0077C8; }
.bg-secondary { background: #E36159; }
.bg-success { background: #47A447; }
.bg-warning { background: #ED9C28; }

/* Labels */
.label-default {
    background: #EBEBEB;
    color: #777;
}

.label-primary,
.label-success,
.label-warning,
.label-danger,
.label-info {
    color: white;
}

.label-newwarning {
    color: #777;
}

.label-primary { background: #0077C8; }
.label-success { background: #47A447; }
.label-warning { background: #ED9C28; }
.label-danger { background: #D2322D; }
.label-info { background: #62B3E2; }
.label-newwarning { background: $warning-main-color; }
.label-pending { background: #8DA4AC; }

/* Buttons */
.btn-group,
 .btn-group-vertical {
    .btn {
        &:hover,
        &:focus,
        &:active,
        &.active {
            z-index: auto !important;
        }
    }
}

.btn {
    white-space: normal;

    &:focus,
    &:active:focus,
    &.active:focus {
        outline: none; 
    }
}

.btn-lg,
.btn-group-lg > .btn {
    line-height: 1.334;
}

.btn-link {
    color: #0077C8;

    &:hover,
    &:focus {
        color: #0099E6;
    }

    &:active {
        color: #0077B3;
    }
}

.btn-default {
    border-color: #AAA;

    &.btn-rounded {
        border-radius: 20px;
        padding: 6px 8px;
        line-height: 20px;
    }
}

.btn-primary {
    color: #FFF;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #0077C8;
    border-color: #0077C8;

    &:hover,
    &:active,
    &:focus,
    &:disabled {
        border-color: #269AD6 !important;
        background-color: #269AD6;

        :hover {
            background-color: #269AD6;
        }
    }

    &.dropdown-toggle {
        border-left-color: #00A3F5;
    }
}

.btn-secondary {
    background-color: #D9D9D9;
    border-color: #D9D9D9 #D9D9D9 #CCC;
    color: #666;

    &:hover {
        background-color: #CCC;
    }

    &:active,
    &:focus {
        border-color: #BFBFBF;
        background-color: #BFBFBF;
    }

    &:disabled {
        border-color: white;
        background-color: white;
    }

    &.dropdown-toggle {
        border-left-color: #BFBFBF;
    }

    &:disabled {
        border-color: white;
        background-color: white;
    }
}

.btn-success {
    background-color: #47A447;
    border-color: #47A447 #47A447 #388038;
    color: white;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

    &:hover {
        border-color: #51B451;
        background-color: #51B451;
        color: white;
    }

    &:active,
    &:focus {
        border-color: #3F923F;
        background-color: #3F923F;
        color: white; 
    }

    &:disabled {
        border-color: #86CB86;
        background-color: #86CB86;

        &:hover {
            background-color: #86CB86;
        }
    }

    &.dropdown-toggle {
        border-left-color: #3F923F;
    }
}

.btn-warning {
    border-color: #ED9C28;
    background-color: #ED9C28;
    color: white;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

    :hover {
        border-color: #EFA740;
        background-color: #EFA740;
        color: white;
    }

    &:active,
    &:focus {
        border-color: #E89113;
        background-color: #E89113;
        color: white;
    }

    :disabled {
        border-color: #F5C786;
        background-color: #F5C786;

        &:hover {
            background-color: #F5C786;
        }
    }

    &.dropdown-toggle {
        border-left-color: #E89113;
    }
}

.btn-danger {
    border-color: #D2322D;
    background-color: #D2322D;
    color: white;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

    &:hover {
        border-color: #D64742;
        background-color: #D64742;
        color: white;
    }

    &:active,
    &:focus {
        border-color: #BD2D29;
        background-color: #BD2D29;
        color: white;
    }

    &:disabled {
        border-color: #E48481;
        background-color: #E48481;

        &:hover {
            background-color: #E48481;
        }
    }

    &.dropdown-toggle {
        border-left-color: #BD2D29;
    }
}

.btn-info {
    color: #FFF;
    text-shadow: none;
    background-color: #4E8CB9;
    border-color: #4E8CB9;

    &:hover {
        color: #FFF;
        border-color: #72A2C5 !important;
        background-color: #72A2C5 !important;
    }

    &:active,
    &:focus {
        color: #FFF;
        border-color: #4081B1 !important;
        background-color: #4081B1 !important;
    }

    &:disabled {
        border-color: #B0E1EF !important;
        background-color: #B0E1EF;

        &:hover {
            color: #FFF;
            background-color: #B0E1EF;
        }
    }

    &.dropdown-toggle {
        border-left-color: #46B8DA;
    }
}

/* Navigation */
.nav-tabs {
    margin: 0;
    font-size: 0;

    li {
        display: inline-block;
        float: none;

        a {
            border-radius: 5px 5px 0 0;
            font-size: 13px;
            font-size: 1.3rem;
            margin-right: 1px;
        }

        a,
        a:hover {
            background: #F4F4F4;
            border-bottom: none;
            border-left: 1px solid #EEE;
            border-right: 1px solid #EEE;
            border-top: 3px solid #DDD;
            color: #555;
        }

        a:hover {
            border-bottom-color: transparent;
            border-top: 3px solid #555;
            box-shadow: none;
        }

        a:active,
        a:focus {
            border-bottom: 0;
        }

        &:last-child a {
            margin-right: 0;
        }

        &.active {
            a,
            a:hover,
            a:focus {
                background: white;
                border-left-color: #EEE;
                border-right-color: #EEE;
                border-top: 3px solid #555;
                color: #555;
            }
        }
    }
}

.nav > li.disabled > a:focus,
.nav > li.disabled > a:hover {
    cursor: default;
}

.pagination {
    > li {
        a {
            color: #000;
        }

        &.active {
            a,
            span {
                background-color: #0077C8;
                border-color: #0077C8;
            }
        }
    }
}

/* Alert */
.alert-info {
    color: #4E8CB9;
    background-color: rgba(229, 248, 255, 0.45);
    border-color: #B2CCDA;
}

.alert-warning {
    border-color: #E6D4AC;
}

.desktop-webview {
    .alert {
        border: 0;
        border-radius: 0;
    }

    .alert-info {
        color: #0077C8;
        background-color: #D2ECFE;
    }
}

.progress {
    background: #474453;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4) inset;

    .progress-bar {
        background-color: #0077C8;
        box-shadow: none;
        border-radius: 4px;
    }
}

.dropdown-menu {
    min-width: 0;

    > .active {
        > a,
        > a:hover,
        > a:focus {
            background-color: #0077C8;
        } 
    }
}

.open > .dropdown-toggle.btn-primary {
    background: #0077C8;
    border-color: #0077B3;
}

.mt-14 { margin-top: 112px; }
.mt-18 { margin-top: 144px; }
.mt-19 { margin-top: 152px; }
.mt-24 { margin-top: 192px; }
.mt-26 { margin-top: 208px; }

.row.gutters-xs-small {
    .col[class^="col-xs-"],
    .col[class*=" col-xs-"] {
        &:not(:last-child) {
            padding-right: 5px;
        }

        &:not(:first-child) {
            padding-left: 5px;
        }
    }
}
@media (min-width: 992px) {
    .row.gutters-md-small {
        .col[class^="col-md-"],
        .col[class*=" col-md-"] {
            &:not(:last-child) {
                padding-right: 5px;
            }

            &:not(:first-child) {
                padding-left: 5px;
            }
        }
    }
}
.has-error .help-block .btn.btn-link.text-danger {
    color: #A94442 !important;
}

.Web_227629_VolksbankTender {
    .text-primary { color: #036 !important; }

    .dropdown-menu {
        > .active {
            > a,
            > a:hover,
            > a:focus {
                background-color: #036;
            }
        }
    }

    .well {
        background-color: #F1FBFE;
        border-color: #D7F2FF;
    }

    .table-striped > tbody > tr {
        &:nth-of-type(odd) {
            background-color: #F1FBFE;
        }

        &:hover {
            background-color: #D7F2FF;
        }
    }

    .btn-primary {
        background-color: #036;
        border-color: #036;

        &:hover {
            background-color: mix(#000, #036, 16%);
            border-color: mix(#000, #036, 16%);
        }

        &:active,
        &:focus {
            background-color: mix($white-color, #036, 16%);
            border-color: mix($white-color, #036, 16%);
        }
    }
}

.desktop-webview {
    .table-hover > tbody > tr:hover {
        background-color: #ECECEC;
    }

    .table > tbody > tr.not-expandable {
        height: 33px;

        &:active,
        &:focus,
        &.selected {
            background-color: #D2ECFE;
        }
    }

    .btn-default,
    .btn-primary,
    .btn-secondary,
    .btn-info,
    .btn-warning,
    .btn-danger {
        transition: none;
        font-family: "Segoe UI";
        border-radius: 0;
        color: #20201F;
        font-weight: 400;
        line-height: 16px;
        padding: 8px;

        box-shadow: none;
        background-color: #F0F0F0;
        border: 1px solid #AFAFAF !important;
        
        &:hover {
            background-color: #C2C1C1;
        }

        &:active,
        &:focus {
            color: #20201F;
            background-color: #AEAEAE;
            border: 1px solid #7E7E7F !important;
        }

        &:disabled {
            background-color: #f4f4f4;
            border: 1px solid #DDDDDD !important;
        }
    }
}
@font-face {
    font-family: "Whitney Medium";
    font-style: normal;
    font-weight: normal;
    src: local("Whitney Medium"), url("/assets/fonts/whitney/whitneymedium.woff") format("woff");
}

@font-face {
    font-family: "Whitney Medium Italic";
    font-style: normal;
    font-weight: normal;
    src: local("Whitney Medium Italic"), url("/assets/fonts/whitney/whitneymediumitalic.woff") format("woff");
}

@font-face {
    font-family: "Whitney Bold";
    font-style: normal;
    font-weight: normal;
    src: local("Whitney Bold"), url("/assets/fonts/whitney/whitneybold.woff") format("woff");
}

@font-face {
    font-family: "Whitney Semibold Regular";
    font-style: normal;
    font-weight: normal;
    src: local("Whitney Semibold Regular"), url("/assets/fonts/whitney/whitneysemibold.woff") format("woff");
}

@font-face {
    font-family: "Whitney Book Regular";
    font-style: normal;
    font-weight: normal;
    src: local("Whitney Book Regular"), url("/assets/fonts/whitney/whitneybook.woff") format("woff");
}

@font-face {
    font-family: "Whitney Light Regular";
    font-style: normal;
    font-weight: normal;
    src: local("Whitney Light Regular"), url("/assets/fonts/whitney/whitneylight.woff") format("woff");
}

@font-face {
    font-family: "Whitney Semibold Italic";
    font-style: normal;
    font-weight: normal;
    src: local("Whitney Semibold Italic"), url("/assets/fonts/whitney/WhitneySemiboldItalic.woff") format("woff");
}

@font-face {
    font-family: "Whitney Book Italic";
    font-style: normal;
    font-weight: normal;
    src: local("Whitney Book Italic"), url("/assets/fonts/whitney/whitneybookitalic.woff") format("woff");
}

@font-face {
    font-family: "Whitney Light Italic";
    font-style: normal;
    font-weight: normal;
    src: local("Whitney Light Italic"), url("/assets/fonts/whitney/whitneylightitalic.woff") format("woff");
}
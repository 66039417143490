.drag-handle {
    content: "....";
    height: 29px;
    width: 15px;
    display: inline-block;
    overflow: hidden;
    line-height: 5px;
    cursor: move !important;
    vertical-align: middle;
    font-size: 12px;
    font-family: sans-serif;
    letter-spacing: 2px;
    color: #CCC;
    text-shadow: 1px 0 1px black;
    background-color: #F5F5F5;
    padding: 1px 2px;

    &::after {
        content: ".. .. .. ..";
    }
}
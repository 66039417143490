@import ".scss/variables/colors";

$font-weight-medium: 500;
$font-weight-semi-bold: 600;

.new-font-styling {
    font-family: initial !important;
    font-feature-settings: initial !important;
    font-kerning: initial !important;
    font-language-override: initial !important;
    font-optical-sizing: initial !important;
    font-size-adjust: initial !important;
    font-size: initial !important;
    font-stretch: initial !important;
    font-style: initial !important;
    font-variant-alternates: initial !important;
    font-variant-caps: initial !important;
    font-variant-east-asian: initial !important;
    font-variant-ligatures: initial !important;
    font-variant-numeric: initial !important;
    font-variant-position: initial !important;
    font-variation-settings: initial !important;
    font-weight: initial !important;
    letter-spacing: initial !important;
    line-height: initial !important;
    text-decoration: initial !important;
    text-rendering: initial !important;
}

.new-font-styling-inherit {
    color: inherit !important;
    font-family: inherit !important;
    font-feature-settings: inherit !important;
    font-kerning: inherit !important;
    font-language-override: inherit !important;
    font-optical-sizing: inherit !important;
    font-size-adjust: inherit !important;
    font-size: inherit !important;
    font-stretch: inherit !important;
    font-style: inherit !important;
    font-variant-alternates: inherit !important;
    font-variant-caps: inherit !important;
    font-variant-east-asian: inherit !important;
    font-variant-ligatures: inherit !important;
    font-variant-numeric: inherit !important;
    font-variant-position: inherit !important;
    font-variation-settings: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit !important;
    line-height: inherit !important;
    text-decoration: inherit !important;
    text-rendering: inherit !important;
}

h1.new-font-styling {
    color: $text-color !important;
    font-family: "Poppins" !important;
    font-size: 32px !important;
    font-weight: bold !important;
}

h2.new-font-styling {
    color: $text-color !important;
    font-family: "Poppins" !important;
    font-size: 22px !important;
    font-weight: $font-weight-semi-bold !important;
}

h3.new-font-styling {
    color: $text-color !important;
    font-family: "Poppins" !important;
    font-size: 17px !important;
    font-weight: $font-weight-medium !important;
}

h4.new-font-styling {
    color: $text-color !important;
    font-family: "Open Sans" !important;
    font-size: 12px !important;
}

h5.new-font-styling {
    color: $text-color !important;
    font-family: "Open Sans" !important;
    font-size: 12px !important;
}

h6.new-font-styling {
    color: $text-color !important;
    font-family: "Open Sans" !important;
    font-size: 12px !important;
}

.fa.new-font-styling,
.fa.new-font-styling-inherit {
    font-family: "FontAwesome" !important;
}

p.new-font-styling {
    color: $text-color !important;
    font-family: "Open Sans" !important;
    font-size: 15px !important;
    line-height: 22px !important;
    letter-spacing: 0 !important;
}

a.new-font-styling {
    font-family: "Open Sans" !important;
    font-size: 15px !important;
    line-height: 22px !important;
    letter-spacing: 0 !important;
}

button.new-font-styling {
    font-family: "Open Sans" !important;
    font-weight: 600 !important;
    font-size: 13px !important;
    line-height: 18px !important;
    letter-spacing: 0 !important;
}

.Web_227629_VolksbankTender {
    h1.new-font-styling,
    h2.new-font-styling {
        font-family: "Whitney Bold" !important;
    }

    h3.new-font-styling {
        font-family: "Whitney Semibold Regular" !important;
    }

    h4 {
        &.text-dark,
        &.card-title,
        &.panel-title {
            font-family: "Whitney Bold" !important;
        }

        &.invoices-summary-container__item__title {
            font-family: "Whitney Bold" !important;
            color: #1D2940;
        }
    }
}

.desktop-webview {
    h1,
    h2,
    h3,
    h5,
    h6,
    p,
    a,
    button {
        &.new-font-styling {
            font-family: "Segoe UI", sans-serif !important;
        }
    }
}
@import "mixins";
@import ".scss/bootstrap/extensions/breakpoints";
@import "bootstrap/extensions/spacing";

a {
    cursor: pointer;
    color: #0077C8;
    outline: none !important;

    &:hover,
    &:focus { color: #0099E6; }
    &:active { color: #0077B3; }

    &:hover,
    &:focus,
    &:active,
    &:visited {
        outline: none !important;
    }
}

/* ------------------------------------------------------------------------------------------------------------------------------------------
BOOTSTRAP EXTEND
------------------------------------------------------------------------------------------------------------------------------------------ */
html {
    &.scroll,
    &.fixed {
        .col-xl-1,
        .col-xl-2,
        .col-xl-3,
        .col-xl-4,
        .col-xl-5,
        .col-xl-6,
        .col-xl-7,
        .col-xl-8,
        .col-xl-9,
        .col-xl-10,
        .col-xl-11,
        .col-xl-12 {
            position: relative;
            min-height: 1px;
            padding-right: 15px;
            padding-left: 15px;
        }
    
        .visible-xl 
        .visible-xl-block {
            display: none !important;
        }
    }
}

/* Alert new state */
.alert-default {
    background-color: #EBEBEB;
    border-color: #E3E3E3;
    color: #6C6C6C;

    .alert-link {
        color: #454545;
    }
}

// Extra bg class
.bg-default {
    background: #EBEBEB;
    color: #777;
}

/* Extra text-color classes */
.text-muted-extra { color: #B3B3B3 !important; }
.text-dark { color: #171717 !important; }
.text-dark-inverse { color: white !important; }

label {
    &.valid {
        display: inline-block;
        text-indent: -9999px;
    }

    &.error {
        color: #B94A48;
        font-size: 0.9em;
        margin-top: 2px;
        padding: 0;
    }
}

.label i {
    padding-right: 1px;
}

.oopsErrorImg {
    margin: 30px 0;
    border-radius: 0 110px 0 0;
}

.changelog {
    h4 {
        display: inline-block;
        color: #000;
        font-size: 1em;
        font-weight: 600;
    }

    .release-date {
        color: #999;
        font-size: 0.9em;
    }

    .label {
        display: inline-block;
        min-width: 100px;
    }
}

// Aanpakken/verwerken met pbi typografie
.text-xs {
    font-size: 10px;
    font-size: 1rem;
}

.text-sm {
    font-size: 13px;
    font-size: 1.3rem;
}

.text-md {
    font-size: 16px;
    font-size: 1.6rem;
}

.text-xl {
    font-size: 22px;
    font-size: 2.2rem;
}

/* weights*/
.text-weight-light { font-weight: 300; } // uitfaseren naar font-weight-light
.text-weight-normal { font-weight: 400; } // uitfaseren naar font-weight-normal
.text-weight-semibold { font-weight: 600; } // uitfaseren naar font-weight-bold
.text-weight-bold { font-weight: 700; } // uitfaseren naar font-weight-bolder (extra class)

/* spacement top & bottom */
.m-none { margin: 0 !important; }
.m-auto { margin: 0 auto !important; }
.m-xs { margin: 5px !important; }
.m-sm { margin: 10px !important; }
.m-md { margin: 15px !important; }
.m-lg { margin: 20px !important; }
.m-xl { margin: 25px !important; }
.m-xlg { margin: 30px !important; }

/* spacement top	*/
.mt-none { margin-top: 0 !important; }
.mt-xs { margin-top: 5px !important; }
.mt-sm { margin-top: 10px !important; }
.mt-md { margin-top: 15px !important; }
.mt-lg { margin-top: 20px !important; }
.mt-xl { margin-top: 25px !important; }
.mt-xlg { margin-top: 30px !important; }

/* spacement bottom	*/
.mb-none { margin-bottom: 0 !important; }
.mb-xs { margin-bottom: 5px !important; }
.mb-sm { margin-bottom: 10px !important; }
.mb-md { margin-bottom: 15px !important; }
.mb-lg { margin-bottom: 20px !important; }
.mb-xl { margin-bottom: 25px !important; }
.mb-xlg { margin-bottom: 30px !important; }

/* spacement left	*/
.ml-none { margin-left: 0 !important; }
.ml-xs { margin-left: 5px !important; }
.ml-sm { margin-left: 10px !important; }
.ml-md { margin-left: 15px !important; }
.ml-lg { margin-left: 20px !important; }
.ml-xl { margin-left: 25px !important; }
.ml-xlg {margin-left: 30px !important; }

/* spacement right	*/
.mr-none { margin-right: 0 !important; }
.mr-xs { margin-right: 5px !important; }
.mr-sm { margin-right: 10px !important; }
.mr-md { margin-right: 15px !important; }
.mr-lg { margin-right: 20px !important; }
.mr-xl { margin-right: 25px !important; }
.mr-xlg { margin-right: 30px !important; }

/* Padding */
.p-none { padding: 0 !important; }
.p-xs { padding: 5px !important; }
.p-sm { padding: 10px !important; }
.p-md { padding: 15px !important; }
.p-lg { padding: 20px !important; }
.p-xl { padding: 25px !important; }
.p-xlg { padding: 30px !important; }

/* spacement top	*/
.pt-none { padding-top: 0 !important; }
.pt-xs { padding-top: 5px !important; }
.pt-sm { padding-top: 10px !important; }
.pt-md { padding-top: 15px !important; }
.pt-lg { padding-top: 20px !important; }
.pt-xl { padding-top: 25px !important; }
.pt-xlg { padding-top: 30px !important; }

/* spacement bottom	*/
.pb-none { padding-bottom: 0 !important; }
.pb-xs { padding-bottom: 5px !important; }
.pb-sm { padding-bottom: 10px !important; }
.pb-md { padding-bottom: 15px !important; }
.pb-lg { padding-bottom: 20px !important; }
.pb-xl { padding-bottom: 25px !important; }
.pb-xlg { padding-bottom: 30px !important; }

/* spacement left	*/
.pl-none { padding-left: 0 !important; }
.pl-xs { padding-left: 5px !important; }
.pl-sm { padding-left: 10px !important; }
.pl-md { padding-left: 15px !important; }
.pl-lg { padding-left: 20px !important; }
.pl-xl { padding-left: 25px !important; }
.pl-xlg { padding-left: 30px !important; }

/* spacement right	*/
.pr-none { padding-right: 0 !important; }
.pr-xs { padding-right: 5px !important; }
.pr-sm { padding-right: 10px !important; }
.pr-md { padding-right: 15px !important; }
.pr-lg { padding-right: 20px !important; }
.pr-xl { padding-right: 25px !important; }
.pr-xlg { padding-right: 30px !important; }

/* Form - Custom Fields */
.required {
    display: inline-block;
    color: #D2322D;
    font-size: 0.8em;
    font-weight: bold;
    position: relative;
    top: -0.2em;
}

/* Form - Group Override */
.form-group {
    &::after {
        clear: both;
        display: block;
        content: "";
    }

    &:last-child,
    &:last-of-type {
        margin-bottom: 0;
    }
}

/* Form - Vertical Group / Stacked */
.form-group-vertical {
    position: relative;
    white-space: nowrap;

    .form-control {
        border-radius: 0;
        margin-top: -1px;
        z-index: 1;

        &:first-child,
        &:first-of-type {
            border-radius: 4px 4px 0 0;
        }

        &:last-child,
        &:last-of-type {
            border-radius: 0 0 4px 4px; 
        }

        &:focus {
            position: relative;
            z-index: 2;
        }
    }
}

.form-group-vertical {
    .input-group {
        margin-top: -1px;

        .form-control {
            margin-top: 0;
        }

        &:first-child,
        &:first-of-type {
            .input-group-addon { border-radius: 4px 0 0; }
            .form-control { border-radius: 0 4px 0 0; }
        }

        &:last-child,
        &:last-of-type {
            .input-group-addon { border-radius: 0 0 0 4px; }
            .form-control { border-radius: 0 0 4px; }
        }

        &.input-group-icon {
            &:first-child,
            &:first-of-type {
                .input-group-addon,
                .form-control { border-radius: 4px 4px 0 0; }
            }

            &:last-child,
            &:last-of-type {
                .input-group-addon,
                .form-control {border-radius: 0 0 4px 4px;}
            }
        }
    }
}   

/* Form - Input Icon */
.input-group-icon,
.input-search {
    width: 100%;
    table-layout: fixed;

    input.form-control {
        font-size: 12px;
        font-size: 1.2rem;
        padding-right: 36px;

        &:first-child,
        &:last-child {
            border-radius: 4px;
        }
    }

    .input-group-btn {
        border-radius: 500px;
        width: 0;

        &:first-child,
        &:last-child {
            border-radius: 500px;
        }

        &:last-child {
            button {
                left: auto;
                right: 0;
            }
        }

        button {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            border: 0;
            z-index: 3;
            background: transparent;

            &:active {
                box-shadow: none;
            }
        }
    }

    .input-group-btn + input.form-control {
        padding-right: 12px;
        padding-left: 36px;
    }
}

.input-group-icon,
.input-search {
    .input-group-addon {
        position: relative;
        padding: 0;
        border: 0 none;
        width: 0;
        color: #8C8C8C;

        span.icon {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            border: 0;
            z-index: 3;
            width: auto;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            padding: 6px 12px;
            background: transparent;
            line-height: 1.42857143;
            box-sizing: content-box;
            pointer-events: none;

            &.icon-lg {
                padding: 10px 14px;
                font-size: 18px;
                z-index: 4;
            }
        }

        &:last-child span.icon {
            left: auto;
            right: 0;
        }
    }

    .input-group-addon + input.form-control {
        padding-right: 12px;
        padding-left: 36px;
    }

    .input-group-addon + input.form-control.input-lg {
        padding-left: 38px;
    }
}

.desktop-webview {
    .input-group-icon,
    .input-search {
        .input-group-addon {
            span.icon {
                &.icon-lg {
                    @include padding-x(1);
                    @include padding-y(0);
                }
            }
        }
    }
}

.loading-overlay-showing {
    overflow: hidden;

    > .loading-overlay {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
        z-index: 5;
    }
}

.loading-overlay {
    transition: visibility 0s ease-in-out 0.5s, opacity 0.5s ease-in-out;
    bottom: 0;
    left: 0;
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
    visibility: hidden;

    &.dark {
        background: #1D2127;
    }

    &.light {
        background: #FFF;
    }
}

.sidebar-left .loading-overlay.light {
    background: #F5F5F5;
}

body > .loading-overlay {
    position: fixed;
    z-index: 999999;
}

.panel[ng-click] {
    cursor: auto;
}

.panel-heading {
    &.panel-heading-with-border {
        border-radius: 5px 5px 0 0 !important;
        border-bottom: 1px solid #DADADA;
    }

    &.bg-darker {
        padding: 10px 15px;
        background: #F6F6F6;
    }
}

.panel-subtitle {
    color: #787878;
    font-size: 13px;
    line-height: 14px;
    margin: 6px 0 0;
    padding: 0;
}

.dashboard .panel-body {
    min-height: 165px;
}

.panel .panel-body .no-revenue {
    height: 290px;
}

.panel-heading + .panel-body {
    border-radius: 0;
}

/* tabs */
.tabs {
    border-radius: 4px;
}

/* Tables*/
.table {
    width: 100%;

    > tbody > tr.background-darker { background-color: #F6F6F6; }

    .table { background: transparent; }

    .b-top-none td {
        border-top: none;
    }

    &.mb-none {
        margin-bottom: -10px !important;
        margin-top: 15px;
    }

    .actions,
    .actions-hover {
        vertical-align: middle;

        a {
            display: inline-block;
            margin-right: 5px;
            color: #666;

            &:hover {
                color: #333;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
    .actions-hover a { opacity: 0; }

    .actions-fade a {
        transition: all 0.2s linear;
    }

    tr:hover .actions-hover a {
        opacity: 1;
    }
}

.table > tbody > tr.background-darker {
    background-color: #F6F6F6;
}

.table-select-list.table {
    > tbody,
    > tfoot,
    > thead { 
        > tr {
            > th,
 
            > td {
                vertical-align: middle;
            }
        }
    }

    thead {
        tr:not(:first-child) {
            background-color: #D8D8D8;
            color: white;
            font-weight: bold;
        }

        td:first-child {
            width: 55px;
        }
    }

    > tbody > tr {
        > td {
            border-top: 1px solid #ADADAD;

            &:not(:first-child) {
                word-wrap: break-word;
                min-width: 160px;
                max-width: 160px;
                white-space: normal;
            }
        } 
    }

    .primary-value {
        font-weight: bold;
    }
}

.fileupload .btn {
    border-radius: 0;
}

.summary .black {
    color: #000;
}

/* Error Pages - wrappers */
.body-error {
    margin: 0 auto;
    max-width: 900px;
    width: 100%;

    .panel-body {
        background: #FFF;
        border-bottom: 5px solid #CCC;
        border-radius: 0;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        padding: 33px 33px 15px;
        border-bottom-color: #FA6105;
    }

    .error-code {
        font-size: 140px;
        font-size: 14rem;
        line-height: 140px;
        line-height: 14rem;
        letter-spacing: -10px;
    }

    .error-explanation {
        font-size: 20px;
        font-size: 2rem;
        line-height: 36px;
        line-height: 3.6rem;
    }

    &.error-inside {
        margin-top: 40px;

        .center-logo {
            margin: 20px 0 30px;
        }
    }
}

.body-sign {
    display: table;
    margin: 0 auto;
    width: 500px;

    .center-sign {
        display: table-cell;
        vertical-align: middle;
    }

    .top-sign {
        display: table-cell;
        padding-top: 40px;
        vertical-align: top;
    }

    .panel-sign {
        background: transparent;

        .panel-body {
            background: #FFF;
            border-bottom: 5px solid #FA6105;
            border-radius: 0;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
            padding: 33px 33px 15px;
        }
    }

    .input-group-icon .input-group-addon i {
        width: 14px;
    }
}

.desktop-webview {
    .body-sign {
        margin: 0;
    }
}

.page-header h2 {
    border-bottom-color: #0077C8;
}

/* Panels */
.panel {
    &.panel-featured-primary {
        border-color: #0077C8 !important;

        .panel-title {
            color: #0077C8;
        }
    }

    &.panel-featured-left {
        border-left-style: solid !important;
        border-left-width: 3px !important;
    }
}

.panel-body-md { max-width: 500px; }

.barrel_roll {
    transform: rotate(360deg);
    transition: transform 4s ease;
}

.well {
    .text-muted {
        color: #B1B1B1;
    }

    &.well-muted {
        background-color: transparent;
        border: 1px solid #E4E4E4;
    }

    &.well-fieldset {
        padding-top: 14px;

        .well-legend {
            border-style: none;
            border-width: 0;
            font-size: 14px;
            margin-bottom: 0;
            width: auto;
        }

        &.well-danger {
            color: #A94442;
            background-color: #F2DEDE;
            border-color: #A94442;

            .well-legend {
                color: #A94442;
            }
        }
    }
}

.input-group.datepicker { width: 140px; }

.panel-sign .input-group-icon .input-group-addon span.icon.icon-lg {
    padding-top: 9px;

    .fa-envelope {
        font-size: 14px;
    }
}

.input-group-addon {
    i { font-size: 16px; }

    &.active {
        i { color: #0077C8; }
    }

    &.focus {
        position: relative;
        z-index: 5;
        border-color: #0099E6;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 136, 204, 0.3);
    }
}

.input-group-addon-vatfield {
    padding: 0;
    min-width: 29px;
}

.has-error {
    .input-group-addon {
        i,
        &.active i { color: #A94442; }

        &.focus {
            border-color: #843534;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #CE8483;
        }
    }

    .btn-group .dropdown .btn {
        border-color: #843534 !important;
        border-style: solid;
    }
}

.input-clear::before {
    position: absolute;
    right: 7px;
    top: 0;
    bottom: 0;
    height: 24px;
    line-height: 24px;
    margin: auto -5px auto 0;
    font-size: 26px;
    color: #636363;
    cursor: pointer;
    z-index: auto;
    font-family: "Open Sans";
    content: "\00d7";
    padding: 0 5px;
}

.input-clear ~ input,
.input-clear ~ .snelstart-typeahead-wrapper input {
    padding-right: 24px;
}

.text-highlight {
    color: #000;
    background-color: #DDD;
}

.correct-fieldset-legend {
    margin-top: -10px;
}

.pull-down {
    position: absolute;
    bottom: 0;
    left: 0;
}

.overlay-white {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    opacity: 0.6;
}

.overlay-white-relative {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    opacity: 0.6;
}

.max-width-md { max-width: 500px; }
.max-width-lg { max-width: 90%; }

.font-weight-normal { font-weight: normal; }
.font-weight-semi-bold { font-weight: 600; }
.font-weight-bold { font-weight: bold; }

html.mobile-device {
    .pagination {
        > li {
            a {
                color: #000;

                &:hover,
                &:focus {
                    color: #0099E6;
                }
            }

            &.active {
                a,
                span {
                    &:hover,
                    &:focus {
                        background-color: #0077C8;
                        border-color: #0077C8;
                    }
                }
            }
        }
    }

    html {
        .pagination > li.active {
            a,
            span {
                &:hover,
                &:focus {
                    background-color: #0077C8;
                    border-color: #0077C8;
                }
            }
        }
    }

    .dropdown-menu .dropdown-item {
        line-height: 34px;
    }

    .input-clear::before {
        color: #525252;
    }

    .input-clear:hover::before {
        color: black;
    }
}

html.no-mobile-device {
    .pagination {
        > li {
            a {
                color: #000;

                &:hover,
                &:focus {
                    color: #0099E6;
                }
            }

            &.active {
                a,
                span {
                    &:hover,
                    &:focus {
                        background-color: #0077C8;
                        border-color: #0077C8;
                    }
                }
            }
        }
    }

    html {
        .pagination > li.active {
            a,
            span {
                &:hover,
                &:focus {
                    background-color: #0077C8;
                    border-color: #0077C8;
                }
            }
        }
    }
}

html.snelstart {
    .list-group-item {
        transition: box-shadow 0.2s, background-color 0.2s;
        padding: 15px;
        margin-bottom: 20px;
        border-radius: 4px;
    
        > span {
            width: 100%;
            margin-top: -3px;
        }
        
        &:nth-child(odd) {
            background-color: #FDFDFD;
        }

        &:not(:disabled):hover,
        &:not(:disabled):focus {
            background-color: #F5F5F5;
            outline: none;
            box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
        }

        &:disabled {
            background-color: #F5F5F5;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .label { 
        padding: 0.3em 0.6em;

        &.code-label {
            padding: 0.4em 0.6em;
        }
        
        &.code-label-flex {
            padding: 0.6em;
        }
    }

    .input-group {
        .form-control {
            &.input-group-first {
                border-radius: 4px 0 0 4px;
            }

            &.input-group-last {
                border-radius: 0 4px 4px 0;
            }
        }    
    }
}

html.desktop-webview {
    .input-group {
        &:first-child,
        &:first-of-type {
            .input-group-addon { border-radius: 0; }
            .form-control { border-radius: 0; }
        }

        &:last-child,
        &:last-of-type {
            .input-group-addon { border-radius: 0; }
            .form-control { border-radius: 0; }
        }

        &.input-group-icon {
            &:first-child,
            &:first-of-type {
                .input-group-addon,
                .form-control { border-radius: 0; }
            }

            &:last-child,
            &:last-of-type {
                .input-group-addon,
                .form-control { border-radius: 0; }
            }
        }
    }

    input.form-control {
        &:first-child,
        &:last-child {
            border-radius: 0;
        }
    }

    .input-group {
        .form-control {
            &.input-group-first {
                border-radius: 0;
            }

            &.input-group-last {
                border-radius: 0;
            }
        }
    }
}

html.dark .text-muted { color: #505461; }

/* Sign Screens - Wrappers */
@media handheld, screen and (min-height: 550px) {
    html.no-mobile-device .body-sign.body-sign-vcenter .center-sign,
    .login-random-background-content {
        padding-top: 50px;
    }
}

@media handheld, screen and (min-height: 750px) {
    html.no-mobile-device .body-sign.body-sign-vcenter .center-sign,
    .login-random-background-content {
        padding-top: 100px;
    }
}

@media handheld, screen and (min-height: 850px) {
    html.no-mobile-device .body-sign.body-sign-vcenter .center-sign,
    .login-random-background-content {
        padding-top: 150px;
    }
}

@media handheld, screen and (min-height: 950px) {
    html.no-mobile-device .body-sign.body-sign-vcenter .center-sign,
    .login-random-background-content {
        padding-top: 200px;
    }
}

@media handheld, screen and (min-height: 1050px) {
    html.no-mobile-device .body-sign.body-sign-vcenter .center-sign,
    .login-random-background-content {
        padding-top: 250px;
    }
}

@include media-breakpoint-down-xs() {
    .content-container {
        overflow-y: auto;
    }

    .body-sign {
        width: 100%;
    }

    .body-error.error-inside {
        .error-code {
            font-size: 90px;
            font-size: 9rem;
            line-height: 90px;
            line-height: 9rem;
            letter-spacing: -7px;
        }

        .error-explanation {
            font-size: 16px;
            font-size: 1.6rem;
            line-height: 28px;
            line-height: 2.8rem;
        }
    }
}

@include media-breakpoint-up-sm() {
    .center-block-sm {
        display: table;
        margin-left: auto;
        margin-right: auto;
    }
}

@media handheld, screen and (min-width: 768px) and (max-width: 1150px) {
    .body-error.error-inside {
        .error-code {
            font-size: 100px;
            font-size: 10rem;
            line-height: 100px;
            line-height: 10rem;
            letter-spacing: -7px;
        }

        .error-explanation {
            font-size: 18px;
            font-size: 1.8rem;
            line-height: 32px;
            line-height: 3.2rem;
        }
    }
}

@media handheld, screen and (max-width: 1150px) {
    .body-error.error-inside {
        margin-top: 50px;
        padding-bottom: 50px;
    }
}

@mixin media-breakpoint-up-md() {
    .pull-right-md {
        float: right;
    }
}

@media handheld, screen and (min-width: 1200px) and (max-width: 1599px) {
    html {
        &.scroll,
        &.fixed {
            .visible-lg,
            .visible-lg-block {
                display: block !important;
            }

            table.visible-lg {
                display: table !important;
            }

            tr.visible-lg {
                display: table-row !important;
            }

            td.visible-lg,
            th.visible-lg {
                display: table-cell !important;
            }

            .visible-lg-inline {
                display: inline !important;
            }

            .visible-lg-inline-block {
                display: inline-block !important;
            }

            .hidden-lg {
                display: none !important;
            }
        }
    }
}

@media handheld, screen and (min-width: 1600px) {
    html {
        &.scroll,
        &.fixed {
            .visible-lg-block {
                display: none !important;
            }

            .visible-xl,
            .visible-xl-block {
                display: block !important;
            }

            table.visible-xl {
                display: table !important;
            }

            .hidden-xl {
                display: none !important;
            }

            .col-xl-1,
            .col-xl-2,
            .col-xl-3,
            .col-xl-4,
            .col-xl-5,
            .col-xl-6,
            .col-xl-7,
            .col-xl-8,
            .col-xl-9,
            .col-xl-10,
            .col-xl-11,
            .col-xl-12 {
                float: left;
            }

            .col-xl-12 {
                width: 100%;
            }

            .col-xl-11 {
                width: 91.66666667%;
            }

            .col-xl-10 {
                width: 83.33333333%;
            }

            .col-xl-9 {
                width: 75%;
            }

            .col-xl-8 {
                width: 66.66666667%;
            }

            .col-xl-7 {
                width: 58.33333333%;
            }

            .col-xl-6 {
                width: 50%;
            }

            .col-xl-5 {
                width: 41.66666667%;
            }

            .col-xl-4 {
                width: 33.33333333%;
            }

            .col-xl-3 {
                width: 25%;
            }

            .col-xl-2 {
                width: 16.66666667%;
            }

            .col-xl-1 {
                width: 8.33333333%;
            }

            .container {
                width: 1570px;
            }
        }
    }
}

.Web_227629_VolksbankTender .table > tbody > tr.background-darker {
    background-color: #F1FBFE;
}
﻿.float-label label {
    z-index: 0;
}

.float-label.focus label {
    z-index: 0;
}

.snelstart-field__icon {
    z-index: auto;
}

.input-group .form-control { 
    position: static;
    z-index: auto;
}

.input-group .form-control:focus { 
    z-index: auto;
}

.dropdown-menu {
    z-index: auto;
}
@import ".scss/variables/colors";
@import "bootstrap/extensions/spacing";
@import "shared/button/button/button-mixins";

.buorg {
    @include padding(2);
    @import "shared/button/button-base.component";

    position: fixed;
    z-index: 111111;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #FFF;
    color: $font-color;
    font-family: "Open Sans";
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    animation: 1s ease-out 0s buorgfly;
}

.buorg-buttons {
    display: block;
    text-align: center;
}

.buorg-icon {
    display: none !important;
}

.buorg-fadeout {
    transition: visibility 0s 8.5s, opacity 8s ease-out 0.5s;
}

#buorgig,
#buorgul,
#buorgpermanent {
    @extend .button;
    @include margin-top(2);

    display: inline-block !important;
    width: auto !important;
    text-decoration: none;
}

#buorgul {
    @include margin-right(2);
    @include button($button-base-secondary);
}

#buorgig,
#buorgpermanent {
    @include button-outline($button-base-secondary);
}

@keyframes buorgfly {
    from {
        opacity: 0;
        transform: translateY(-50px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}
@import ".scss/variables/colors";
@import ".scss/bootstrap/extensions/breakpoints";

$font-size: 13px;

:host {
    display: inline-block;
    width: 100%;
    font-size: $font-size;

    @include media-breakpoint-up-xs() {
        width: auto;
    }
}

.button {
    $icon-size: 16px;
    $line-height: 18px;

    justify-content: center;
    width: 100%;
    min-width: 120px;
    border-radius: 6px;
    border: none;
    padding: 12px 24px;
    outline: none;
    font-family: "Open Sans";
    font-weight: 600;
    line-height: $line-height;
    transition: all 0.1s ease-in-out;

    @include media-breakpoint-up-xs() {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    > span {
        font-size: $font-size;
    }

    &.has-icon-left > span {
        margin-left: 4px;
    }

    &.has-icon-right > span {
        margin-right: 4px;
    }

    &:hover,
    &:active,
    &:focus {
        outline: none;
    }

    &:disabled,
    &:disabled:hover {
        cursor: not-allowed;
    }
}

.spin {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

:host-context(.desktop-webview) {
    .button {
        font-family: "Segoe UI";
    }
}
@import ".scss/bootstrap/config";
@import ".scss/bootstrap/extensions/breakpoints";

$spacer: 8px;

@mixin padding-left($n, $important: null) {
    padding-left: $n * $spacer $important;
}

@mixin padding-right($n, $important: null) {
    padding-right: $n * $spacer $important;
}

@mixin padding-top($n, $important: null) {
    padding-top: $n * $spacer $important;
}

@mixin padding-bottom($n, $important: null) {
    padding-bottom: $n * $spacer $important;
}

@mixin padding($n, $important: null) {
    padding: $n * $spacer $important;
}

@mixin padding-x($n, $important: null) {
    @include padding-left($n, $important);
    @include padding-right($n, $important);
}

@mixin padding-y($n, $important: null) {
    @include padding-top($n, $important);
    @include padding-bottom($n, $important);
}

@mixin margin-left($n, $important: null) {
    margin-left: $n * $spacer $important;
}

@mixin margin-right($n, $important: null) {
    margin-right: $n * $spacer $important;
}

@mixin margin-top($n, $important: null) {
    margin-top: $n * $spacer $important;
}

@mixin margin-bottom($n, $important: null) {
    margin-bottom: $n * $spacer $important;
}

@mixin margin($n, $important: null) {
    margin: $n * $spacer $important;
}

@mixin margin-x($n, $important: null) {
    @include margin-left($n, $important);
    @include margin-right($n, $important);
}

@mixin margin-y($n, $important: null) {
    @include margin-top($n, $important);
    @include margin-bottom($n, $important);
}
@import ".scss/bootstrap/config";

// idea taken from bootstrap 4 breakpoints for easy transition to bootstrap 4 in time
// https://getbootstrap.com/docs/4.1/layout/overview/#responsive-breakpoints
// https://github.com/twbs/bootstrap/blob/master/scss/mixins/_breakpoints.scss

// breakpoint and up queries
// xs: 480px sm: 768px md: 992px lg: 1200px

@mixin media-breakpoint-up-xs() {
    @media (min-width: $screen-xs-min) {
        @content;
    }
}

@mixin media-breakpoint-up-sm() {
    @media (min-width: $screen-sm-min) {
        @content;
    }
}

@mixin media-breakpoint-up-md() {
    @media (min-width: $screen-md-min) {
        @content;
    }
}

@mixin media-breakpoint-up-lg() {
    @media (min-width: $screen-lg-min) {
        @content;
    }
}

// breakpoint only queries

@mixin media-breakpoint-only-xs() {
    @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
        @content;
    }
}

@mixin media-breakpoint-only-sm() {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        @content;
    }
}

@mixin media-breakpoint-only-md() {
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        @content;
    }
}

// breakpoint and down queries

@mixin media-breakpoint-down-xs() {
    @media (max-width: $screen-xs-max) {
        @content;
    }
}

@mixin media-breakpoint-down-sm() {
    @media (max-width: $screen-sm-max) {
        @content;
    }
}

@mixin media-breakpoint-down-md() {
    @media (max-width: $screen-md-max) {
        @content;
    }
}

@mixin media-breakpoint-down-lg() {
    @media (max-width: $screen-lg) {
        @content;
    }
}

// between breakpoints queries

@mixin media-breakpoint-between-xs-and-sm() {
    @media (min-width: $screen-xs-min) and (max-width: $screen-sm-max) {
        @content;
    }
}

@mixin media-breakpoint-between-sm-and-md() {
    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
        @content;
    }
}

@mixin media-breakpoint-between-xs-and-md() {
    @media (min-width: $screen-xs-min) and (max-width: $screen-md-max) {
        @content;
    }
}
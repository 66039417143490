@import "mixins";

.card-editable {
    @include transition(box-shadow 0.2s ease);
    @include box-shadow(0, 0, 7px, rgba(0, 0, 0, 0));
    cursor: pointer !important;
    position: relative;

    .edit-icon {
        @include transition(transform 0.2s ease-in);
        position: absolute;
        top: 25px;
        right: 20px;
        font-size: 15px;
    }

    &:hover {
        @include box-shadow(0, 0, 7px, rgba(0, 0, 0, 0.2));
        background-color: #F1EFEF;
        
        .edit-icon {
            @include scale(1.5);
            @include transition(transform 0.2s ease-in);
            color: #0077C8;
        }
    }

    &:active .edit-icon {
        @include scale(1.5);
        @include transition(transform 0.2s ease-in);
        color: #0077B3;
    }
}
@import "mixins";
@import ".scss/bootstrap/extensions/breakpoints";
@import ".scss/variables/colors";

/*
    Start global helper classes
*/

.flex-horizontal {
    display: flex;
    flex-direction: row;
}

.flex-vertical {
    display: flex;
    flex-direction: column;
}

.flex-vertical-reverse {
    display: flex;
    flex-direction: column-reverse;
}

.flex-horizontal-reverse {
    display: flex;
    flex-direction: row-reverse;
}

.flex-incompressible {
    flex-shrink: 0;
}

.flex-stretch {
    flex-grow: 1;
}

.flex-wrap {
    flex-wrap: wrap;
}

.space-around {
    justify-content: space-around;
}

.space-between {
    justify-content: space-between;
}

.align-self-center {
    align-self: center;
}

.align-self-end {
    align-self: end;
}

.align-items-center {
    align-items: center;
}

.reverse-direction {
    direction: rtl;
}

.reverse-direction > * {
    direction: ltr;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.smooth-scroll {
    @include smooth-scroll();
}

/*
    End global helper classes
*/

/*
    Start styling for index.html or global
*/
html,
body {
    height: 100% !important;
}

body {
    margin: 0;
    color: #777;
    background: #ECEDF0;
    font-family: "Open Sans", Arial, sans-serif;
    line-height: 22px;
    font-size: 13px;
    min-height: stretch !important;
}

.ie-scroll-fix {
    overflow: hidden;
}

* {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
}

/*
    End styling for index.html or global
*/

/*
    Dumped here from Okler
*/

/* Headings */
h1,
h2,
h3,
.h1,
.h2,
.h3 {
    letter-spacing: -1px;
}

h6 {
    letter-spacing: 0;
}

h1 i,
h2 i,
h3 i {
    color: #0077C8;
}

hr {
    border: 0;
    height: 1px;
    background: linear-gradient(to left, transparent, rgba(0, 0, 0, 0.2), transparent);
    margin: 22px 0;
}

.flex-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.position-absolute {
    position: absolute;
}

.position-relative {
    position: relative;
}

.cursor-zoom-in {
    cursor: zoom-in !important;
}

.cursor-default {
    cursor: default;
}

.cursor-pointer {
    cursor: pointer !important;
}

.ws-normal {
    white-space: normal;
}

.lh-normal {
    line-height: normal;
}

.center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.vertical-align-top {
    vertical-align: top;
}

.vertical-align-bottom {
    vertical-align: bottom;
}

.vertical-align-middle {
    vertical-align: middle;
}

.display-none {
    display: none;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

.display-table-cell {
    display: table-cell;
}

.display-table {
    display: table;
    width: 100%;
}

.display-inline-flex {
    display: inline-flex;
}

.display-inline-grid {
    display: inline-grid;
}

.border-none {
    border: none;
}

.p-none {
    padding: 0 !important;
}

.outline-none {
    outline: none;
}

.rounded {
    border-radius: 5px;
}

.negative-currency {
    color: #D00 !important;
}

.center-fixed-width {
    margin: 0 auto;
}

.highlight {
    background-color: #0077C8;
}

.icon-cell {
    text-overflow: unset !important;
}

.font-size-1up {
    font-size: 14px !important;
}

.font-size-2up {
    font-size: 15px !important;
}

.font-size-3up {
    font-size: 16px !important;
}

.nowrap,
.white-space-nowrap {
    white-space: nowrap;
}

.word-wrap-break-word {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
    hyphens: auto;
}

.i-block {
    display: block;
}

ul,
ol {
    margin-bottom: 0;
    padding-left: 27px;
}

.numerical,
.numerical input {
    text-align: right;
    white-space: nowrap;
}

.full-width {
    width: 100%;
}

/* Form - iOS Override */
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
textarea {
    appearance: none;
}

input[type="number"] {
    appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
    }
}

textarea[data-toggle=autosize] {
    transition: height 0.15s ease-in;
}

select {
    border: 1px solid #E5E7E9;
    border-radius: 6px;
    height: 46px;
    padding: 12px;
    outline: none;
}

a.disabled,
.checkbox.disabled,
.radio.disabled {
    opacity: 0.8;
    text-decoration: line-through;
    cursor: not-allowed;
}

.content-container {
    overflow-y: visible;
}

.visible-xxs {
    display: initial;
}

.hidden-xxs {
    display: none;
}

@include media-breakpoint-up-xs {
    .visible-xxs {
        display: none;
    }

    .hidden-xxs {
        display: inherit;
    }
}

.Web_227629_VolksbankTender {
    body {
        background-color: #F1FBFE;
    }
}

.desktop-webview {
    body {
        font-family: "Segoe UI", sans-serif;
        background-color: $white-color;
    }
}
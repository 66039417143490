@import ".scss/bootstrap/extensions/breakpoints";

.invoice-line {
    display: table;
    width: 100%;
    margin-bottom: 10px;
    margin-left: -6px;

    &.head {
        font-weight: bold;
        margin-bottom: 6px;
    }

    &.text-line {
        margin-bottom: 0;
    }
}

.invoice-line {
    .col {
        padding-left: 8px;
        float: left;
        min-height: 1px;
        line-height: 34px;
        position: relative;

        .well {
            line-height: 22px;
        }
    }

    .col:not(.col-article-info) .help-block {
        font-size: 12px;
        position: sticky;
        top: 107px;
        background: #F2DEDE;
        padding: 4px;
        border: 1px solid #A94442;
        white-space: nowrap;
        line-height: 16px;
        z-index: auto;
    }

    .col-quantity { width: 10%; }
    .col-description { width: 47%; }
    .col-price { width: 23%; }
    .col-total { width: 15%; }
    .col-actions { width: 5%; }
    .col-article-info { width: 57%; }
    .col-underlined { border-bottom: 1px solid #BFBFBF; }
    .col-total.col-underlined { width: 18%; }

    .col-total-prefix {
        width: 80%;
        padding-right: 10px;
    }

    span.col-value {
        font-size: 14px;

        &.grand-total {
            font-size: 17px;
        }
    }

    .invoice-discount {
        float: right;

        &.input-group {
            width: 100px;
            padding-right: 10px;
        }
    }

    .fa-trash,
    .fa-cog {
        font-size: 14px;
        padding: 5px;
    }
}

.invoice-line .col-discount,
.invoice-line-table .total-row .discount {
    width: 110px;
    padding-left: 8px;
}

.invoice-line-table .total-row td.border-top {
    border-top: 1px solid #BFBFBF;
}

@include media-breakpoint-up-lg() {
    .invoice-line {
        .col-quantity { width: 8%; }
        .col-description { width: 54%; }
        .col-price { width: 18%; }
        .col-article-info { width: 62%; }
    }
}
@import 'mixins';
@import '.scss/bootstrap/extensions/breakpoints';

.snelstart-typeahead-wrapper {
  position: relative;
}

typeahead-container.dropdown-menu {
  overflow-y: unset !important;
  padding: unset !important;
  margin: unset !important;
  border: unset !important;
}

.snelstart-dropdown-menu {
  position: absolute;
  z-index: 900;
  border: 1px solid rgb(0 0 0 / 15%);
  border-radius: 0 0 4px 4px;
  width: 100%;
  margin-top: -1px;

  ul.dropdown-menu {
    overflow-y: auto;
    max-height: 100px;
    padding: 0;
    list-style: none;
    border: none;
    border-radius: 0 0 4px 4px;
    display: block;
    position: relative;
    margin: 0;
    width: 100%;
    overflow-x: hidden;

    li {
      a {
        padding: 0 12px 3px;
        line-height: 31px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.active {
          background-color: #0077c8;
          color: white;
        }
      }

      i.fa-plus {
        font-size: 17px;
        font-size: 1.7rem;
        margin-right: 3px;
        vertical-align: middle;
      }

      &.snelstart-dropdown-menu-item-disabled {
        a,
        a:hover,
        a:focus {
          background-color: white;
          color: #999;
          cursor: auto;
        }
      }
    }

    &.zebra-striped {
      li:nth-child(even):not(.active) a {
        background-color: #f5f5f5;
      }
    }
  }
}

html.no-mobile-device
  .snelstart-dropdown-menu
  ul.dropdown-menu
  li.snelstart-dropdown-menu-item-disabled {
  a,
  a:hover,
  a:focus {
    background-color: white;
    color: #999;
    cursor: auto;
  }
}

.mobile-device .snelstart-dropdown-menu ul.dropdown-menu li a {
  line-height: 38px;
}

@include media-breakpoint-down-xs {
  .input-group.focus {
    @include box-shadow(0, 0, 30px, rgba(0, 0, 0, 0.3), false);

    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    margin: 15px 15px 0;

    input {
      border-radius: 0;
    }

    .input-group-addon {
      border-radius: 0;

      &.focus {
        border-color: #ccc;
        box-shadow: none;
      }
    }

    .snelstart-dropdown-menu {
      position: fixed;
      border: none;

      ul.dropdown-menu {
        @include box-shadow(0, 15px, 30px, rgba(0, 0, 0, 0.6), false);

        max-height: calc(100vh - 70px);
      }
    }
  }

  html.snelstart {
    .input-group.focus {
      .form-control.input-group-last {
        border-radius: 0;
      }

      .form-control:focus {
        border: 1px solid #ccc;
        box-shadow: none;
      }
    }

    .has-error .input-group.focus .form-control:focus {
      border-color: #843534;
    }
  }
}

@media screen and (min-height: 700px) {
  .snelstart-dropdown-menu ul.dropdown-menu {
    max-height: 200px;
  }
}

@media screen and (min-height: 800px) {
  .snelstart-dropdown-menu ul.dropdown-menu {
    max-height: 300px;
  }
}

@import "bootstrap/extensions/spacing";
@import ".scss/bootstrap/extensions/breakpoints";

snelstart-button + snelstart-button-text {
    @include margin-top(2);
    width: inherit;

    .button {
        padding: 0 !important;
        min-width: 0 !important;
    }
}

@include media-breakpoint-up-xs {
    snelstart-button + snelstart-button-text {
        @include margin-left(2);
        margin-top: 0;
    }
}
@import "mixins";
@import ".scss/variables/colors";
@import ".scss/bootstrap/extensions/breakpoints";

// Mobile first approach

.grid-container {
    padding: 5px;
    
    .search {
        max-width: 220px;
        display: inline-block;

        &.search-with-filter {
            max-width: 50%;
        }
    }

    .filter {
        max-width: 50%;
        float: right;
    }

    td.full-height {
        height: 450px;
        vertical-align: baseline;
    }
}

.grid-container {
    .st-sort::before,
    .st-sort-ascent::before,
    .st-sort-descent::before {
        font-family: FontAwesome;
        margin-right: 7px;
        font-size: 13px;
    }
}

.grid-container {
    .table {
        table-layout: fixed;
        
        > thead > tr.content > td,
        > thead > tr.content > th,
        > tbody > tr.content > td,
        > tbody > tr.content > th,
        > tfoot > tr.content > td,
        > tfoot > tr.content > th {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 0;
            font-size: 14px;
        }

        > tbody > tr.content > td,
        > tbody > tr.content > th,
        > tfoot > tr.content > td,
        > tfoot > tr.content > th {
            line-height: 35px;
            i { line-height: inherit; }
        }

        tr.content {
            i.fa-phone,
            i.fa-envelope {
                font-size: 17px;
            }
        }

        > thead > tr.content > th.expand,
        > tbody > tr.content > td.expand {
            min-width: 27px;
            width: 27px;
            text-overflow: clip !important;
            text-align: center;
            color: #000;
            font-size: 12px;
            padding-left: 5px;
        }
    }
}

.grid-container .table > tbody {
    > tr.background-odd { background-color: $background-color; }
    > tr.not-expandable { cursor: auto; }

    > tr.master-detail {
        &:not(:last-child) {
            @include box-shadow-args(inset 0 8px 8px -8px rgba(0, 0, 0, 0.3), inset 0 -8px 8px -8px rgba(0, 0, 0, 0.2));
        }
        
        &:last-child {
            @include box-shadow-args(inset 0 8px 8px -8px rgba(0, 0, 0, 0.3));
        }

        td {
            line-height: inherit;
    
            .well .label-wrapper {
                font-size: 14px;
            }
        }
    }
}

.desktop-webview {
    .grid-container .table > tbody {
        > tr.master-detail {
            box-shadow: none;
        }
    }
}

.grid-container {
    .pagination {
        margin: 20px 0 0;

        > li {
            > a {
                padding: 10px 12px;
                &:active { background-color: #DDD; } 
            }

            > span {
                padding: 10px 12px;
                color: #777;
                &:hover { background-color: $white-color; }
            }      
        }

        input.select-page {
            width: 50px;
            height: 24px;
            text-align: center;
            padding: 0;
            margin: -5px 4px;
            display: inline;
        }
    }
}

.expand {
    .fa-stack { transition: transform 0.5s ease; } 
    .fa-circle { color: #EFEFEF; }
}

html.no-mobile-device .grid-container .table > tbody > tr.expandable:hover {
    background-color: #ECECEC;
    cursor: pointer;
}

.no-mobile-device {
    .grid-container {
        .table tr.content:hover .fa-chevron-down {
            color: $theme-primary-color;
        }

        .st-sort {
            &:hover,
            &:focus {
                background-color: #EEE;
                cursor: pointer;
                &::before { content: "\f07d"; }
            }
            &:active { background-color: #DDD; }
            &.st-sort-ascent::before { content: "\f176"; }
            &.st-sort-descent::before {content: "\f175"; }            
        }
    }
}

@include media-breakpoint-up-sm() {
    .grid-container {
        padding: 15px;
    }

    td.full-height {
        vertical-align: middle;
    }
}

@include media-breakpoint-up-md() {
    .grid-container {
        .search.search-with-filter {
            max-width: 220px;
            float: left;
        }

        .table {
            > tbody > tr.content > td,
            > tbody > tr.content > th,
            > tfoot > tr.content > td,
            > tfoot > tr.content > th {
                line-height: 2;
                i {line-height: inherit; }
            }

            > thead > tr.content {
                > td,
                > th,
                > td i,
                th i {
                    line-height: 2.4;
                    color: #000;
                }
            }
        }
    }
}

.animate-highlight-grid {
    animation: target-fade 4s;
}

@keyframes target-fade {
    from { background-color: #F9F2D1; }
    to { background-color: transparent; }
}

.Web_227629_VolksbankTender .grid-container .table > tbody > tr.background-odd {
    background-color: #F1FBFE;
}

html.no-mobile-device.Web_227629_VolksbankTender .grid-container .table > tbody > tr.expandable:hover {
    background-color: #D7F2FF;
}
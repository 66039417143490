@import "mixins";
@import ".scss/variables/colors";
@import ".scss/bootstrap/extensions/breakpoints";

// mobile first approach

.modal-content {
    @include box-shadow(0, 0, 40px, rgba(0, 0, 0, 0.5));
    @include background-clip(padding-box);
    color: #000;
    border: rgba(0, 0, 0, 0);
    background-color: $white-color;
    border-radius: 6px; 
}

.modal-message {
    .modal-body,
    .modal-footer,
    .modal-header,
    .modal-title {
        background: 0 0;
        border: none;
        margin: 0;
        padding: 0 20px;
        text-align: center !important;
    }
}

.modal-message  .modal-body {
    color: #737373;

    .content,
 
    .alert {
        margin: 15px 0;
    }

    .content {
        .left-aligned {
            text-align: left;
            margin-left: auto;
            margin-right: auto;
            display: table;
        }
    }
}

.modal-message .modal-header {
    color: $white-color;
    margin-bottom: 10px;
    padding: 15px 0 8px;

    .fa,
    .glyphicon,
    .typcn,
    .wi {
        font-size: 35px;
    }

    .fa.fa-close {
        font-size: 18px;
        color: #000;

        &:hover {
            color: #555;
        }        
    }
}

.modal-message .modal-footer {
    margin: 25px 0 20px;
    padding-bottom: 10px;

    &.no-button {
        margin: 0;
        padding-bottom: 0;
    }
}

.modal-message  .modal-title {
    font-size: 17px;
    color: #737373;
    margin-bottom: 3px;
}

.modal-backdrop {
    background-color: $white-color;

    &.show {
        transform: scale(1);
        transform-origin: 0 0;
        opacity: .75;
    }
}

.modal-message.modal-success .modal-header {
    color: $modal-success;
    border-bottom: 3px solid $modal-success-border;
}

.modal-message.modal-info .modal-header {
    color: $modal-info;
    border-bottom: 3px solid $modal-info-border;
}

.modal-message.modal-warning .modal-header {
    color: $modal-warning;
    border-bottom: 3px solid $modal-warning-border;
}

.modal-message.modal-danger {
    .modal-header {
        color: $modal-danger;
        border-bottom: 3px solid $modal-danger-border;
    }

    code { font-size: 14px; }
}

.modal-message.modal-snelstart {
    .modal-header {
        color: $theme-primary-color;
        border-top: 4px solid $theme-primary-color;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        font-size: 20px;
    }

    h4 {
        color: #000;
        margin-bottom: 0;
    }

    label { font-weight: bold; }
    .float-label label { font-weight: normal; }

    .form-group {
        background: rgba(238, 238, 238, 0.63);
        margin-bottom: 24px;
        padding: 4px 20px 10px;
    }

    .well-fieldset {
        padding: 4px 14px 14px;
        margin: 4px 0 0;
    }
}

.modal-video {
    margin: 40px 10px 0 !important;

    .modal-content {
        border-radius: 0;
        line-height: 0;
    }
}

.modal-close-cross {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 18px;
    cursor: pointer;

    &:hover {
        color: #555;
    }   
}

.modal-pop-up .modal-content {
    border-radius: 0;
}

.modal-dialog.modal-fullscreen {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    & > .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
        box-shadow: none;
    }

    &.modal-nobackground > .modal-content {
        background: 0 0;
    }
}

.modal.fade.show .modal-dialog {
    opacity: 1;
}

.modal.fade .modal-dialog{
    transform: none !important;
}

@include media-breakpoint-up-sm() {
    .modal {
        text-align: center;
        @include translate(0,25%);

        &::before { 
            vertical-align: middle;
        }
    }

    .modal-dialog {
        display: inline-block;
        text-align: left;
        vertical-align: middle;
        padding-bottom: 10%;
    }

    .modal.fade .modal-dialog {
        @include scale(0.9);
        @include transition(all 0.3s ease);
        opacity: 0;
    } 

    .modal.fade.in .modal-dialog {
        @include scale(1);
        opacity: 1;
    }
}

.modal .modal-content .help-block {
    text-align: left;
}
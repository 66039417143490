/* Generated by Glyphter (http://www.glyphter.com) on  Thu Dec 13 2018*/
@font-face {
    font-family: "SnelStart";
    src: url("/assets/fonts/SnelStart.eot?v5");
    src: url("/assets/fonts/SnelStart.eot?v5#iefix") format("embedded-opentype"),
        url("/assets/fonts/SnelStart.woff?v5") format("woff"), 
        url("/assets/fonts/SnelStart.ttf?v5") format("truetype"),
        url("/assets/fonts/SnelStart.svg?v5#SnelStart") format("svg");
    font-weight: normal;
    font-style: normal;
}

[class*="snelstarticon-"] {
    display: inline-block;
    font: normal normal normal 14px/1 SnelStart;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: -15%;
}

.snelstarticon-2x {
    font-size: 2em;
}

.snelstarticon-3x {
    font-size: 3em;
}

.administration-list-item:hover .snelstarticon-administraties::before {
    content: "\0022";
}
.snelstarticon-help::before {content: "\0041";}
.snelstarticon-informatie::before {content: "\0042";}
.snelstarticon-administraties::before {content: "\0043";}
.snelstarticon-backupMaken::before {content: "\0044";}
.snelstarticon-backupTerugzetten::before {content: "\0045";}
.snelstarticon-gebruikers::before {content: "\0046";}
.snelstarticon-kopieren::before {content: "\0047";}
.snelstarticon-licenties::before {content: "\0048";}
.snelstarticon-bewerken::before {content: "\0049";}
.snelstarticon-openen::before {content: "\004a";}
.snelstarticon-opties::before {content: "\004b";}
.snelstarticon-optimaliseren::before {content: "\004c";}
.snelstarticon-overzicht::before {content: "\004d";}
.snelstarticon-importeren::before {content: "\004e";}
.snelstarticon-exporteren::before {content: "\004f";}
.snelstarticon-vrijgeven::before {content: "\0050";}
.snelstarticon-afsluiten::before {content: "\0051";}
.snelstarticon-bankieren::before {content: "\0052";}
.snelstarticon-nieuw::before {content: "\0053";}
.snelstarticon-opslaan::before {content: "\0054";}
.snelstarticon-annuleren::before {content: "\0055";}
.snelstarticon-verwijderen::before {content: "\0056";}
.snelstarticon-verversen::before {content: "\0057";}
.snelstarticon-zoeken::before {content: "\0058";}
.snelstarticon-gebruiker::before {content: "\0059";}
.snelstarticon-inkoop::before {content: "\005a";}
.snelstarticon-orderbeheer::before {content: "\0062";}
.snelstarticon-orderarchief::before {content: "\0063";}
.snelstarticon-omzet::before {content: "\0064";}
.snelstarticon-leveranciers::before {content: "\0065";}
.snelstarticon-klanten::before {content: "\0066";}
.snelstarticon-kortingsgroep::before {content: "\0067";}
.snelstarticon-historie::before {content: "\0068";}
.snelstarticon-assembleren::before {content: "\0069";}
.snelstarticon-prijs::before {content: "\006a";}
.snelstarticon-actieprijs::before {content: "\006b";}
.snelstarticon-analyseerVerkoopprijs::before {content: "\006c";}
.snelstarticon-geldlade::before {content: "\006d";}
.snelstarticon-factuuranalyse::before {content: "\006e";}
.snelstarticon-sjabloon::before {content: "\006f";}
.snelstarticon-lijstGrafiek::before {content: "\0070";}
.snelstarticon-grootboekrekening::before {content: "\0071";}
.snelstarticon-omzetgroepen::before {content: "\0072";}
.snelstarticon-reserveren::before {content: "\0073";}
.snelstarticon-muteren::before {content: "\0074";}
.snelstarticon-artikelen::before {content: "\0075";}
.snelstarticon-aangifte::before {content: "\0076";}
.snelstarticon-boekjaarWisselen::before {content: "\0077";}
.snelstarticon-aanmaning::before {content: "\0078";}
.snelstarticon-voertuigen::before {content: "\0079";}
.snelstarticon-apkMailing::before {content: "\007a";}
.snelstarticon-financiel::before {content: "\0030";}
.snelstarticon-overzichten::before {content: "\0031";}
.snelstarticon-resultaten::before {content: "\0032";}
.snelstarticon-dashboard::before {content: "\0033";}
.snelstarticon-instellingen::before {content: "\0034";}
.snelstarticon-sorteren::before {content: "\0035";}
.snelstarticon-rechten::before {content: "\0036";}
.snelstarticon-opschonen::before {content: "\0037";}
.snelstarticon-top10Klanten::before {content: "\0038";}
.snelstarticon-kasEnBank::before {content: "\0039";}
.snelstarticon-saldo::before {content: "\0021";}
.snelstarticon-AdministratiesHover::before {content: "\0022";}
.snelstarticon-Factuur::before {content: "\0023";}
.snelstarticon-chipje::before {content: "\0024";}
.snelstarticon-afschriften_inlezen::before {content: "\0025";}
.snelstarticon-inStap::before {content: "\0026";}
.snelstarticon-inKaart::before {content: "\0027";}
.snelstarticon-inBalans::before {content: "\0028";}
.snelstarticon-inZicht::before {content: "\0029";}
.snelstarticon-inOrde::before {content: "\002b";}
.snelstarticon-Offerte::before {content: "\002a";}
.snelstarticon-Speedbooks::before {content: "\e900";}
.snelstarticon-Flow::before {content: "\e901";}
.snelstarticon-Rapport::before {content: "\e902";}
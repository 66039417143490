html.export-view {
    .export-view-control {
        display: inline-block;
        z-index: 99999;
    }

    .card-info .amount {
        font-size: 18px;
    }

    .card-footer:not(.include-in-export) {
        display: none !important;
    }
}

.export-view-control {
    display: none;
}

html.export-view {
    .page-header:not(.include-in-export) {
        visibility: hidden;
    }

    .exclude-from-export,
    .card-footer:not(.include-in-export),
    #gf-Bobblehead-1:not(.include-in-export),
    aside:not(.include-in-export),
    button:not(.include-in-export),
    snelstart-header:not(.include-in-export),
    
    snelstart-spinner {
        display: none !important;
    }
}

html.export-view {
    .col-p-1 { width: calc(100% * 1 / 12) !important; }
    .col-p-2 { width: calc(100% * 2 / 12) !important; }
    .col-p-3 { width: 25% !important; }
    .col-p-4 { width: calc(100% * 4 / 12) !important; }
    .col-p-5 { width: calc(100% * 5 / 12) !important; }
    .col-p-6 { width: 50% !important; }
    .col-p-7 { width: calc(100% * 7 / 12) !important; }
    .col-p-8 { width: calc(100% * 8 / 12) !important; }
    .col-p-9 { width: 75% !important; }
    .col-p-10 { width: calc(100% * 10 / 12) !important; }
    .col-p-11 { width: calc(100% * 11 / 12) !important; }
    .col-p-12 { width: 100% !important; }

    .col-p-1,
    .col-p-2,
    .col-p-3,
    .col-p-4,
    .col-p-5,
    .col-p-6,
 
    .col-p-7,
    .col-p-8,
    .col-p-9,
    .col-p-10,
    .col-p-11,
    .col-p-12 {
        float: left !important;
    }
}

@media print {
    * { overflow: visible !important; } 

    body,
    html {
        height: 100% !important;
    }

    .exclude-from-print {
        display: none !important;
    }
}
﻿/* poppins-100 - latin */
@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 100;
    src: local("Poppins Thin"), local("Poppins-Thin"),
        url("/assets/fonts/poppins/poppins-v12-latin-100.woff2") format("woff2"), 
        url("/assets/fonts/poppins/poppins-v12-latin-100.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-100italic - latin */
@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 100;
    src: local("Poppins Thin Italic"), local("Poppins-ThinItalic"),
        url("/assets/fonts/poppins/poppins-v12-latin-100italic.woff2") format("woff2"), 
        url("/assets/fonts/poppins/poppins-v12-latin-100italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-200 - latin */
@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 200;
    src: local("Poppins ExtraLight"), local("Poppins-ExtraLight"),
        url("/assets/fonts/poppins/poppins-v12-latin-200.woff2") format("woff2"), 
        url("/assets/fonts/poppins/poppins-v12-latin-200.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-200italic - latin */
@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 200;
    src: local("Poppins ExtraLight Italic"), local("Poppins-ExtraLightItalic"),
        url("/assets/fonts/poppins/poppins-v12-latin-200italic.woff2") format("woff2"), 
        url("/assets/fonts/poppins/poppins-v12-latin-200italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-300 - latin */
@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    src: local("Poppins Light"), local("Poppins-Light"),
        url("/assets/fonts/poppins/poppins-v12-latin-300.woff2") format("woff2"), 
        url("/assets/fonts/poppins/poppins-v12-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-300italic - latin */
@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 300;
    src: local("Poppins Light Italic"), local("Poppins-LightItalic"),
        url("/assets/fonts/poppins/poppins-v12-latin-300italic.woff2") format("woff2"), 
        url("/assets/fonts/poppins/poppins-v12-latin-300italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-regular - latin */
@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    src: local("Poppins Regular"), local("Poppins-Regular"),
        url("/assets/fonts/poppins/poppins-v12-latin-regular.woff2") format("woff2"), 
        url("/assets/fonts/poppins/poppins-v12-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-italic - latin */
@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 400;
    src: local("Poppins Italic"), local("Poppins-Italic"),
        url("/assets/fonts/poppins/poppins-v12-latin-italic.woff2") format("woff2"), 
        url("/assets/fonts/poppins/poppins-v12-latin-italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-500 - latin */
@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    src: local("Poppins Medium"), local("Poppins-Medium"),
        url("/assets/fonts/poppins/poppins-v12-latin-500.woff2") format("woff2"), 
        url("/assets/fonts/poppins/poppins-v12-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-500italic - latin */
@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 500;
    src: local("Poppins Medium Italic"), local("Poppins-MediumItalic"),
        url("/assets/fonts/poppins/poppins-v12-latin-500italic.woff2") format("woff2"), 
        url("/assets/fonts/poppins/poppins-v12-latin-500italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-600 - latin */
@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    src: local("Poppins SemiBold"), local("Poppins-SemiBold"),
        url("/assets/fonts/poppins/poppins-v12-latin-600.woff2") format("woff2"), 
        url("/assets/fonts/poppins/poppins-v12-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-600italic - latin */
@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 600;
    src: local("Poppins SemiBold Italic"), local("Poppins-SemiBoldItalic"),
        url("/assets/fonts/poppins/poppins-v12-latin-600italic.woff2") format("woff2"), 
        url("/assets/fonts/poppins/poppins-v12-latin-600italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-700 - latin */
@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    src: local("Poppins Bold"), local("Poppins-Bold"),
        url("/assets/fonts/poppins/poppins-v12-latin-700.woff2") format("woff2"), 
        url("/assets/fonts/poppins/poppins-v12-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-700italic - latin */
@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 700;
    src: local("Poppins Bold Italic"), local("Poppins-BoldItalic"),
        url("/assets/fonts/poppins/poppins-v12-latin-700italic.woff2") format("woff2"), 
        url("/assets/fonts/poppins/poppins-v12-latin-700italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-800 - latin */
@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 800;
    src: local("Poppins ExtraBold"), local("Poppins-ExtraBold"),
        url("/assets/fonts/poppins/poppins-v12-latin-800.woff2") format("woff2"), 
        url("/assets/fonts/poppins/poppins-v12-latin-800.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-800italic - latin */
@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 800;
    src: local("Poppins ExtraBold Italic"), local("Poppins-ExtraBoldItalic"),
        url("/assets/fonts/poppins/poppins-v12-latin-800italic.woff2") format("woff2"), 
        url("/assets/fonts/poppins/poppins-v12-latin-800italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-900 - latin */
@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 900;
    src: local("Poppins Black"), local("Poppins-Black"),
        url("/assets/fonts/poppins/poppins-v12-latin-900.woff2") format("woff2"), 
        url("/assets/fonts/poppins/poppins-v12-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-900italic - latin */
@font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 900;
    src: local("Poppins Black Italic"), local("Poppins-BlackItalic"),
        url("/assets/fonts/poppins/poppins-v12-latin-900italic.woff2") format("woff2"), 
        url("/assets/fonts/poppins/poppins-v12-latin-900italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
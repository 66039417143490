.snelstart-twirl {
    animation-name: twirl;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.snelstart-twirl-ie-fix {
    animation-iteration-count: 0 !important;
}

@keyframes twirl {
    from { transform: rotatey(0deg); }
    to { transform: rotatey(360deg);  }
}
@import "mixins";
@import ".scss/variables/colors";
@import ".scss/bootstrap/extensions/breakpoints";

.toast-container {
    position: fixed;
    z-index: 9002;
}

.toast-container .ngx-toastr {
    .toast-content {
        @include box-shadow(0, 2px, 4px, rgba(0, 0, 0, 0.3));
        border-radius: 4px;
        background-color: #030303;
        color: #FFF;
        font-weight: bold;
        padding: 10px;
        cursor: default !important;
        overflow: hidden;
        line-height: 16px;

        &::before {
            font-size: 20px;
            margin: 0 auto;
            display: inline-block;
            background: white;
            height: 35px;
            width: 35px;
            text-align: center;
            border-radius: 35px;
            padding-top: 10px;
            margin-right: 12px;
        }
    }

    .fa-close {
        color: white;
    }
}

.toast-container {
    .toast-success .toast-content {
        background-color: $toast-success;

        &::before {
            font-family: "Glyphicons Halflings";
            content: "\e013";
            color: $toast-success;;
        }
    }

    .toast-info .toast-content {
        background-color: $toast-info;

        &::before {
            font-family: "FontAwesome";
            content: "\f129";
            color: $toast-info;
        }
    }

    .toast-warning .toast-content {        
        background-color: $toast-warning;

        &::before {
            font-family: "FontAwesome";
            content: "\f071";
            color: $toast-warning;
            font-size: 17px;
        }        
    }

    .toast-error .toast-content {   
        @include animation(shakeit, 0.5s, both);
        
        background-color: $toast-error;

        &::before {
            font-family: "Glyphicons Halflings";
            content: "\e104";
            color: $toast-error;
        }        
    }
}

@keyframes shakeit {
    from,
    to { @include transform(translate3d(0, 0, 0)); }

    10%,
    50%,
    90% { @include transform(translate3d(-5px, 0, 0));}

    30%,
    70% { @include transform(translate3d(5px, 0, 0)); }
}

@keyframes slideInTop {
    from {
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    to { transform: translate3d(0, 0, 0); }
}

@keyframes slideInRight {
    from {
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }

    to { transform: translate3d(0, 0, 0); }
}

@include media-breakpoint-down-xs() {
    .toast-container {
        .ngx-toastr .toast-content {
            width: 100%;
            @include animation(slideInTop, 0.5s, both);
        }

        &.toast-position {
            padding: 6px 5px 0;
            top: 0;
            right: 0;
            width: 100%;
        }
    }

    .slideInUp {
        animation-name: slideInUp;
    }
}

@include media-breakpoint-up-sm() {
    .toast-container {
        .ngx-toastr .toast-content {
            @include animation(slideInRight, 0.5s, both);
            border-radius: 4px 0 0 4px;
            width: 600px;
        }

        &.toast-position {
            top: 30px;
            right: 0;
        }
    }

    .slideInRight {
        animation-name: slideInRight;
    }
}
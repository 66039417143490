@import ".scss/variables/colors";

.float-label {
    position: relative;
    display: block;

    &.focus label {
        z-index: 4;
    }

    label {
        position: absolute;
        top: -0.5em;
        left: 12px;
        cursor: text;
        font-size: 80%;
        opacity: 1;
        transition: top 200ms, font-size 200ms;
        z-index: 3;
        line-height: 1;
        padding: 0 1px;
        pointer-events: none;

        &::after {
            content: " ";
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            display: block;
            background: $white-color;
            height: 3px;
            z-index: -1;
        }
    }
}

.has-error .float-label label {
    color: #A94442;
}

.float-label {
    .form-control[disabled] ~ label::after {
        background: #EEE;
    }

    .form-control.snelstart-empty:not(:focus) ~ label {
        opacity: 0.5;
        top: 10px;
        font-size: 14px;
        right: 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-bottom: 5px;

        &::after {
            background-color: transparent;
        }
    }

    [typeahead] ~ label {
        transition-delay: 350ms;
    }
}

.input-group {
    > .float-label {
        display: table-cell;
        width: 100%;
    }

    .float-label .form-control {
        border-radius: 4px;
    }

    .float-label:not(:last-child) .form-control {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }

    .float-label:not(:first-child) .form-control {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
}
@import ".scss/variables/colors";

.snelstart-field__icon {
    position: absolute;
    color: $theme-primary-color;
    opacity: 0.54;
    width: 42px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    z-index: 500 !important;
}

.snelstart-field__icon ~ select {
    width: calc(100% - 44px);
}

input ~ .snelstart-field__icon {
    top: 50%;
    transform: translateY(-50%);
    left: initial;
}

.tooltip-inner {
    font-family: "Open Sans", Arial, sans-serif;
    background-color: $theme-primary-color;
    text-align: left;
    padding: 8px;
}

td.purchase-grid-has-attachments .tooltip-inner {
    max-width: 100%;
}

td.invoice-grid-has-attachments .tooltip-inner {
    max-width: 100%;
}

.tooltip.top .tooltip-arrow {
    border-top-color: $theme-primary-color;
}

.tooltip.in {
    opacity: 1;
}

.mobile-device {
    .tooltip-inner {
        max-width: initial;
    }

    .snelstart-field__icon {
        opacity: 1;

        &:hover {
            opacity: 1;
            cursor: pointer;
        }
    }
}
